import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthorizeRequest, Bootstrap, Screen, UISettings } from './renditions';

export type ScreenRouterCurrent = {
  screen: Screen,
  request: AuthorizeRequest
  rawRequestUrl: string

}
export type ScreenRouterContext = {
  current: ScreenRouterCurrent
  onNavigate: (next: ScreenRouterCurrent) => void
  ui: UISettings
}

export const ScreenRouterContext = React.createContext<ScreenRouterContext>({
  onNavigate: () => {}
} as any);
export default function ScreenRouter(props: {
  initial: Bootstrap,
  children: React.ReactNode
}) {
  const [current, setCurrent] = useState(() => ({
    screen: props.initial.screen,
    request: props.initial.authorizeRequest,
    rawRequestUrl: props.initial.rawRequestUrl
  }));
  
  const onNavigate = useCallback((next: ScreenRouterCurrent) => {
    history.pushState(next, '', next.rawRequestUrl);
    setCurrent(next);
    window.scrollTo(0, 0);
  }, []);
    
  const value = useMemo(() => ({
    current,
    onNavigate,
    ui: props.initial.ui
  }), [current, onNavigate, props.initial.ui]);

  useEffect(() => {
    const listener = (event: PopStateEvent) => {
      const state : ScreenRouterCurrent = event.state ?? props.initial;
      setCurrent(state);
      window.scrollTo(0, 0);
    }

    window.addEventListener('popstate', listener);
    return () => window.removeEventListener('popstate', listener);
  }, []);

  return (
    <ScreenRouterContext.Provider value={value}>
      {props.children}
    </ScreenRouterContext.Provider>
  )
}

export function useScreen() {
  const context = useContext(ScreenRouterContext);
  return context.current;
}

export function useNavigate() {
  const context = useContext(ScreenRouterContext);
  return context.onNavigate;
}

export  function useUISettings() {
  const context = useContext(ScreenRouterContext);
  return useMemo(() => ({
    hasCustomStyling: !!context.ui?.customUIStylesheet || !!context.ui?.tenantStylesheet
  }), [context.ui]);
}