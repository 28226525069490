import "@fontsource/ibm-plex-sans/400.css";
import styles from './Terms.module.css';
import logo from './images/criipto-logo.png';

export default function DanishMitIDTerms(props: {
  language: string
}) {
  const handleClick = (event: React.MouseEvent) => {
    const message = 
      props.language === 'da' ? 'Ved at læse vores privatlivspolitik forlader du muligvis dit MitID login forsøg.' :
      'By reading our privacy policy you may be abandoning your MitID login attempt.'

    if (!window.confirm(message)) {
      event.preventDefault();
    }
  }

  return (
    <div className={styles.terms}>
      Powered by Criipto
      <div className="links">
        <a href="https://www.mitid.dk/hjaelp/mitid-support/" target="_blank" rel="noopener noreferrer">
          {props.language === 'da' ? 'Hjælp til MitID' : 'MitID support'}
        </a>&nbsp;-&nbsp;
        <a href="https://www.criipto.com/legal/mitid-privacy-policy" target="_blank" rel="noopener noreferrer" onClick={handleClick}>Privacy policy</a>
      </div>
    </div>
  )
}