import React, { useEffect, useMemo } from 'react';
import { AuthMethodButton, AuthButtonGroup, Language, Action, actions, QRCode, useCriiptoVerify, OAuth2Error, filterAcrValues, AuthMethodSelectorSweden } from '@criipto/verify-react';
import { parseAuthorizeOptionsFromUrl } from '@criipto/oidc';
import '@criipto/verify-react/dist/criipto-verify-react.css';
import './App.css';
import Frame from '../components/Frame';
import { ViewVersion } from '../utils';
import Header from '../components/Header';
import { LocalizedTexts } from '../components/Language';
import Footer from '../components/Footer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import "@fontsource/roboto-slab/300.css";
import { executeResponse, convertReactResult } from '../response';
import CancelTexts from '../components/CancelTexts';
import { AuthMethodSelector_SelectPageModel, Bootstrap, ClientConfiguration } from '../renditions';
import { useNavigate } from '../screen-router';

export type AuthMethodSelectorProps = {
  viewVersion: ViewVersion
  requestUrl: string
  clientConfiguration: ClientConfiguration
  model: AuthMethodSelector_SelectPageModel
  allowImmediateRedirect?: boolean
}

export default function App(props: AuthMethodSelectorProps) {
  const {result} = useCriiptoVerify();
  const navigate = useNavigate();
  const {model, viewVersion, requestUrl, clientConfiguration} = props;
  const {allAcrValues} = model;
  const params = useMemo(() => parseAuthorizeOptionsFromUrl(requestUrl!), [requestUrl]);

  const language = (model.language ?? params.ui_locales ?? 'en') as Language;
  const action = parseAction(params.login_hint) ?? 'login';
  const configuredAcrValues = clientConfiguration.acr_values_enabled ?? [];
  const requestAcrValues = params.acr_values && !Array.isArray(params.acr_values) ? [params.acr_values] : (params.acr_values ?? []) as string[];

  if (requestAcrValues.includes('urn:grn:authn:se:bankid')) {
    if (!requestAcrValues.includes('urn:grn:authn:se:bankid:same-device')) {
      requestAcrValues.push('urn:grn:authn:se:bankid:same-device');
    }
    if (!requestAcrValues.includes('urn:grn:authn:se:bankid:another-device:qr')) {
      requestAcrValues.push('urn:grn:authn:se:bankid:another-device:qr');
    }
  }

  const unrestrictedByConfig = !configuredAcrValues.length;
  const unrestrictedByRequest = !requestAcrValues.length;
  const acrValues = filterAcrValues(allAcrValues.filter(s => {
    if (s === 'urn:grn:authn:se:bankid') return false;
    if (unrestrictedByRequest) return unrestrictedByConfig || configuredAcrValues.includes(s);
    return requestAcrValues.includes(s);
  }));
  const onlySweden = !!acrValues.length && acrValues.every(s => s.startsWith('urn:grn:authn:se:bankid:'));

  useEffect(() => {
    if (!result) return;

    let isSubscribed = true;
    (async () => {
      if (!isSubscribed) return;
      if ("code" in result && result.source === 'QRCode') {
        const body = new URLSearchParams();
        body.append('code', result.code);
        body.append('client_id', params.client_id);

        const exchangeResponse = await fetch('/Criipto/QrExchange', {
          method: 'POST',
          body: body.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        if (exchangeResponse.status >= 400) {
          const payload = await exchangeResponse.json().catch(() => ({error: 'server_error', state: params.state}));
          const response = convertReactResult({
            result: new OAuth2Error(payload.error, payload.error_description, payload.state ?? params.state),
            responseMode: params.response_mode as any,
            responseType: params.response_type as any,
            redirectUri: params.redirect_uri!,
          });
          executeResponse(window, response);
          return;
        }
      }

      const response = convertReactResult({
        result,
        responseMode: params.response_mode as any,
        responseType: params.response_type as any,
        redirectUri: params.redirect_uri!,
        state: params.state
      });

      executeResponse(window, response);
    })();

    return () => {
      isSubscribed = false
    };
  }, [result, params]);

  const handleCancel = () => {
    const response = convertReactResult({
      result: new OAuth2Error('access_denied', undefined, params.state),
      responseMode: params.response_mode as any,
      responseType: params.response_type as any,
      redirectUri: params.redirect_uri!,
    });
    executeResponse(window, response);
  };

  useEffect(() => {
    if (result) return;
    if (acrValues.length !== 1) return;
    if (props.allowImmediateRedirect === false) return;
    const acrValue = acrValues[0];
    window.location.href = acrValueToLink(acrValue);
  }, [acrValues, result, props.allowImmediateRedirect]);

  const retryText = (
    <LocalizedTexts
      texts={[
        {language: 'en', text: 'Retry'},
        {language: 'da', text: 'Prøv igen'},
        {language: 'sv', text: 'Försök igen'},
        {language: 'nb', text: 'Prøv igjen'}
      ]}
    />
  );

  const loadScreen = async (event: React.MouseEvent, acrValue: string) => {
    event.preventDefault();
    if (acrValue === 'urn:grn:authn:se:bankid:same-device') return;
    const href = acrValueToLink(acrValue);
    const USE_SMART_ROUTING = false;

    if (USE_SMART_ROUTING) {
      try {
        if (acrValue.startsWith('urn:grn:authn:fi:')) {
          const response = await fetch(href, {
            headers: {
              accept: 'application/json'
            }
          });
          const payload = await response.json() as Bootstrap;
          navigate({
            screen: payload.screen,
            request: payload.authorizeRequest,
            rawRequestUrl: payload.rawRequestUrl
          });
          return;
        }
      } catch (err) {
        console.error(err);
        window.location.href = href;
        return;
      }
    }

    window.location.href = href;
  }

  return (
    <React.Fragment>
      {viewVersion === 'unified' && (
        <Header
          title={
            <LocalizedTexts
              // TODO: consider action!
              texts={[
                {language: 'en', text: 'Select login method'},
                {language: 'da', text: 'Vælg login metode'},
                {language: 'sv', text: 'Välj inloggningsmetod'},
                {language: 'nb', text: 'Velg påloggingsmetode'}
              ]}
            />
          }
        />
      )}
      <Frame>
        {onlySweden ? (
          <AuthMethodSelectorSweden
            acrValues={acrValues}
            language={language}
          />
        ) : (
          <div className="criipto-eid-selector">
            <AuthButtonGroup>
              {acrValues.map(acrValue => (
                <AuthMethodButton
                  key={acrValue}
                  acrValue={acrValue}
                  href={undefined}
                  onClick={(event) => loadScreen(event, acrValue)}
                  language={language}
                  action={action}
                />
              ))}
            </AuthButtonGroup>
            <QRCode margin={2} acrValues={acrValues} className="criipto-qr-element">
              {({qrElement, isAcknowledged, isCancelled, isEnabled, retry, error, redirect}) => isEnabled ? (
                <div className="criipto-qr-code">
                  {isCancelled ? (
                    <p>
                      <LocalizedTexts
                        texts={[
                          {language: 'en', text: 'Login cancelled.'},
                          {language: 'da', text: 'Login afbrudt.'},
                          {language: 'sv', text: 'Inloggning avbrudt.'},
                          {language: 'nb', text: 'Pålogging avbrutt.'}
                        ]}
                      />&nbsp;
                      <button onClick={retry}>{retryText}</button>
                    </p>
                  ) : error ? (
                    <p>
                      <LocalizedTexts
                        texts={[
                          {language: 'en', text: 'An error occurred.'},
                          {language: 'da', text: 'Der skete en fejl.'},
                          {language: 'sv', text: 'Ett fel uppstod.'},
                          {language: 'nb', text: 'En feil oppstod.'}
                        ]}
                      />
                      {error.message}.&nbsp;
                      <button onClick={retry}>{retryText}</button>
                    </p>
                  ) : isAcknowledged ? (
                    <p>
                      <LocalizedTexts
                        texts={[
                          {language: 'en', text: 'Complete login on your device.'},
                          {language: 'da', text: 'Gennemfør login på din mobiltelefon.'},
                          {language: 'sv', text: 'Logga in på din mobiltelefon.'},
                          {language: 'nb', text: 'Logg inn på mobiltelefonen.'}
                        ]}
                      />&nbsp;
                      <button onClick={retry}>{retryText}</button>
                    </p>
                  ) : (
                    <React.Fragment>
                      <p>
                        <LocalizedTexts
                          texts={[
                            {language: 'en', text: 'Scan QR code to login via your mobile device'},
                            {language: 'da', text: 'Skan QR koden for at logge ind via din mobiltelefon'},
                            {language: 'sv', text: 'Skanna QR-koden för att logga in via din mobila enhet'},
                            {language: 'nb', text: 'Skann QR-koden for å logge på via din mobile enhet'}
                          ]}
                        />
                      </p>
                      {qrElement}
                    </React.Fragment>
                  )}
                </div>
              ) : <React.Fragment />}
            </QRCode>

            <button className="criipto-verify-button criipto-eid-btn criipto-cancel-btn" onClick={handleCancel}>
              <div className="criipto-eid-logo">
                <FontAwesomeIcon icon={faBan} />
              </div>
              <CancelTexts />
            </button>
          </div>
        )}
      </Frame>
      {viewVersion === 'unified' && (
        <Footer language={language} />
      )}
    </React.Fragment>
  ) 
}

export function acrValueToLink(input: string) {
  const url = new URL(window.location.origin + window.location.pathname + (window.location.search || ''));
  url.searchParams.set('acr_values', input);
  return url.toString();
}

function parseAction(input?: string) : Action | undefined {
  if (!input) return;

  const segments = input.split(" ");
  const actionCandidate = segments.find(s => s.startsWith('action:'));
  if (!actionCandidate) return;
  const action = actionCandidate.replace('action:','');
  if (actions.includes(action as any)) return action as Action;
  return;
}

function acrValueToProviderPrefix(value: string) {
  value = value.replace('urn:grn:authn:', '');

  if (value.startsWith('fi')) {
    return 'fi';
  }
  else if (value.startsWith('itsme')) {
    return 'itsme';
  } else {
    return value.split(':').slice(0, 2).join(':');
  }
}
