export {default as mpkitelia1} from './mpki.telia.1.png';
export {default as tupasaktia1} from './tupas.aktia.1.png';
export {default as tupasalandsbanken1} from './tupas.alandsbanken.1.png';
export {default as tupasdanske1} from './tupas.danske.1.png';
export {default as tupashandelsbanken1} from './tupas.handelsbanken.1.png';
export {default as tupasnordea1} from './tupas.nordea.1.png';
export {default as tupasomasp1} from './tupas.omasp.1.png';
export {default as tupaspop1} from './tupas.pop.1.svg';
export {default as tupassp1} from './tupas.sp.1.png';
export {default as tupasspankki1} from './tupas.spankki.1.png';
export {default as samlopfi} from './saml.op.fi.png';
export {default as samlop1} from './saml.op.1.png';
