import { Language } from "@criipto/verify-react";
import React from "react";
import { LocalizedTexts } from "./Language";

import './Footer/Footer.css';
export default function Footer(props: {language: Language, afterDescription?: React.ReactNode}) {
  const language = props.language;
  const texts = language === 'en' ? [{language, text: ''}] : [{language, text: ''}, {language: 'en' as typeof language, text: ''}];
  
  return (
    <React.Fragment>
      <aside id="description" className="centered default-hidden desktop-show">
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
      </aside>
      {props.afterDescription}
      <footer id="footer" className="default-hidden desktop-show">
        <div className="inner centered"></div>
      </footer>
    </React.Fragment>
  )
}