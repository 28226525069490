import { Language } from '@criipto/verify-react';
import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Frame from '../components/Frame';
import Header from '../components/Header';
import { LocalizedTexts } from '../components/Language';
import { ViewVersion } from '../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import * as logos from './logos';

import "@fontsource/roboto-slab/300.css";
import './App.css';
import CancelTexts from '../components/CancelTexts';
import { FinnishTelia_SelectPageModel } from '../renditions';

export type FinlandSelectorProps = {
  viewVersion: ViewVersion
  model: FinnishTelia_SelectPageModel
}

function generateHref(baseRequest: string, acrValue: string) {
  const url = new URL(baseRequest);
  url.searchParams.set('acr_values', acrValue);
  return url.href;
}

function getLogo(acrValue: string) {
  acrValue = acrValue.replace(/\./g, '');

  return (logos as {[key: string]: string})[acrValue] ?? null;
}

// const loginText = 
//     language === 'da' ? 'Login med' :
//     language === 'sv' ? 'Logga in med' :
//     language === 'nb' ? 'Logg inn med' :
//     language === 'fi' ? 'Kirjaudu sisään' : 
//     'Login with';
  
export default function App(props: FinlandSelectorProps) {
  const {viewVersion, model} = props;
  const {language, selectedAcrValues, allAcrValues, baseRequest, cancelUrl, error} = model;
  const acrValues = selectedAcrValues.length ? selectedAcrValues : allAcrValues;
  const fiMobile = acrValues.find(s => s === 'mpki.telia.1');

  useEffect(() => {
    if (language === 'da') document.title = 'Vælg login metode';
    else if (language === 'sv') document.title = 'Välj inloggningsmetod';
    else if (language === 'nb') document.title = 'Velg påloggingsmetode';
    else if (language === 'fi') document.title = 'Valitse kirjautumistapa';
    else if (language === 'en') document.title = 'Select login method';
    else document.title = 'Select login method';
  }, [language]);

  const terms = (
    <div className="finland-terms centered">
      <LocalizedTexts
        texts={[
          {language: 'en', text: (
            <React.Fragment>
              These transactions are secured with the&nbsp;
              <a href="https://www.telia.fi/yrityksille/palvelut/luottamuspalvelut/tunnistuspalvelu" target="_blank">Telia Identification service</a>.&nbsp;
              <a href="https://www.telia.fi/tietosuoja-ja-tietoturva/tietosuojalausunto" target="_blank">Privacy and security</a>
            </React.Fragment>
          )},
          {language: 'da', text: (
            <React.Fragment>
              Disse transaktioner er sikret med&nbsp;
              <a href="https://www.telia.fi/yrityksille/palvelut/luottamuspalvelut/tunnistuspalvelu" target="_blank">Telia Identification service</a>.&nbsp;
              <a href="https://www.telia.fi/tietosuoja-ja-tietoturva/tietosuojalausunto" target="_blank">Privacy and security</a>
            </React.Fragment>
          )},
          {language: 'sv', text: (
            <React.Fragment>
              Dessa transaktioner är säkrade med&nbsp;
              <a href="https://www.telia.fi/yrityksille/palvelut/luottamuspalvelut/tunnistuspalvelu" target="_blank">Telia Identifieringstjänst</a>.&nbsp;
              <a href="https://www.telia.fi/tietosuoja-ja-tietoturva/tietosuojalausunto" target="_blank">Säkerhet</a>
            </React.Fragment>
          )},
          {language: 'nb', text: (
            <React.Fragment>
              Disse transaksjonene er sikret med&nbsp;
              <a href="https://www.telia.fi/yrityksille/palvelut/luottamuspalvelut/tunnistuspalvelu" target="_blank">Telia Identification service</a>.&nbsp;
              <a href="https://www.telia.fi/tietosuoja-ja-tietoturva/tietosuojalausunto" target="_blank">Privacy and security</a>
            </React.Fragment>
          )},
          {language: 'fi', text: (
            <React.Fragment>
              Tämä asiointi on suojattu <a href="https://www.telia.fi/yrityksille/palvelut/luottamuspalvelut/tunnistuspalvelu" target="_blank">Telia Tunnistus</a>-palvelun avulla.&nbsp;
              <a href="https://www.telia.fi/tietosuoja-ja-tietoturva/tietosuojalausunto" target="_blank">Tietosuoja ja -turva</a>
            </React.Fragment>
          )}
        ]}
      />
    </div>
  );

  return (
    <React.Fragment>
      {viewVersion === 'unified' && (
        <Header
          title={
            <LocalizedTexts
              texts={[
                {language: 'en', text: 'Select login method'},
                {language: 'da', text: 'Vælg login metode'},
                {language: 'sv', text: 'Välj inloggningsmetod'},
                {language: 'nb', text: 'Velg påloggingsmetode'},
                {language: 'fi', text: 'Valitse kirjautumistapa'}
              ]}
            />
          }
        />
      )}

      <Frame className="finland-selector">
        {error ? (
          <div className="finland-error">
            {error.error === 'access_denied' ? (
              <LocalizedTexts
                texts={[
                  {language: 'en', text: 'Login cancelled'},
                  {language: 'da', text: 'Login annulleret'},
                  {language: 'sv', text: 'Inloggningen avbröts'},
                  {language: 'nb', text: 'Innlogging kansellert'},
                  {language: 'fi', text: 'Tunnistautuminen keskeytettiin'} // "Login interrupted", the cancel message displayed by OP bank
                ]}
              />
            ) : (
              <React.Fragment>
                {error.error}{error.error_description ? ` (${error.error_description})` : null}
              </React.Fragment>
            )}
          </div>
        ) : null}

        <div className="finland-bankid">
          {acrValues.filter(s => s !== fiMobile).map(acrValue => (
            <a 
              key={acrValue}
              className={`option option-${acrValue.replace(/\./g, '-')}`}
              href={generateHref(baseRequest, acrValue)}
              title={BANK_NAMES[acrValue] ?? undefined}
              aria-label={BANK_NAMES[acrValue] ?? undefined}
            >
              {getLogo(acrValue) ? <img src={getLogo(acrValue)} alt={BANK_NAMES[acrValue] ? `${BANK_NAMES[acrValue]} logo` : undefined} /> : acrValue}
            </a>
          ))}
        </div>
        {fiMobile ? (
          <a
            className={`finland-mobileid option option-${fiMobile.replace(/\./g, '-')}`}
            href={generateHref(baseRequest, fiMobile)}
            title={BANK_NAMES[fiMobile] ?? undefined}
            aria-label={BANK_NAMES[fiMobile] ?? undefined}
          >
            {getLogo(fiMobile) ? <img src={getLogo(fiMobile)} alt={BANK_NAMES[fiMobile] ? `${BANK_NAMES[fiMobile]} logo` : undefined} /> : fiMobile}

            <LocalizedTexts
              texts={[
                {language: 'en', text: 'Mobile certificate'},
                {language: 'da', text: 'Mobilcertifikat'},
                {language: 'sv', text: 'Mobil Id'},
                {language: 'nb', text: 'Mobilsertifikat'},
                {language: 'fi', text: 'Mobiilivarmenne'}
              ]}
            />
          </a>
        ) : null}
        {cancelUrl ? (
          <a className={`finland-cancel option option-cancel`} href={cancelUrl}>
            <FontAwesomeIcon icon={faBan} />
            <CancelTexts />
          </a>
        ) : null}
      </Frame>

      {viewVersion === 'unified' ? (
        <Footer language={language as Language} afterDescription={terms} />
      ) : terms}
    </React.Fragment>
  );
}

export const BANK_NAMES : {[key: string]: string} = {
  'mpki.telia.1': 'Mobiilivarmenne',
  'tupas.aktia.1': 'Aktia Bank',
  'tupas.alandsbanken.1': 'Ålandsbanken',
  'tupas.danske.1': 'Danske Bank',
  'tupas.handelsbanken.1': 'Handelsbanken',
  'tupas.nordea.1': 'Nordea Bank',
  'tupas.omasp.1': 'OmaSp',
  'tupas.pop.1': 'POP-pankki',
  'tupas.sp.1': 'Säästöpankki',
  'tupas.spankki.1': 'S-Pankki',
  'saml.op.fi': 'OP Bank',
  'saml.op.1': 'OP Bank',
}