import React from 'react';
import { Language } from "@criipto/verify-react";
import { useUISettings } from "../screen-router";
import DanishMitIDTerms from "./Terms";
import Footer from "../components/Footer";

export default function DanishMitIDFooter(props: {language: Language}) {
  const {hasCustomStyling} = useUISettings();

  return (
    <React.Fragment>
      {!hasCustomStyling ? <DanishMitIDTerms language={props.language} /> : null}
      <Footer language={props.language} />
      {hasCustomStyling ? <DanishMitIDTerms language={props.language} /> : null}
    </React.Fragment>
  )
}