import cx from 'classnames';
import mitid_logo from '../images/dkmitid_logo.svg';
import localidp_logo from '../images/localidp.svg';

import styles from './LocalIdPTabs.module.css';

export default function LocalIdPTabs(props: {
  selected: 'mitid' | 'localidp',
  onSelect: (target: 'mitid' | 'localidp') => void
}) {
  return (
    <div className={styles.tabs}>
      <div onClick={() => props.onSelect('localidp')} className={cx(styles.tab, styles.localidp, {[styles.active]: props.selected === 'localidp'})}>
        <img src={localidp_logo} />
      </div>
      <div onClick={() => props.onSelect('mitid')} className={cx(styles.tab, styles.mitid, {[styles.active]: props.selected === 'mitid'})}>
        <img src={mitid_logo} />
      </div>
    </div>
  );
}