import React from 'react';
import { Language } from "@criipto/verify-react";

type LocalizedTextInput = {text: string | React.ReactElement, language: Language | "fi" | "nl"};
export function LocalizedText(props: LocalizedTextInput) {
  return (
    <span className={`lang lang-${props.language}`}>
      <span className="text">{props.text}</span>
    </span>
  );
}

type LocalizedTextsInput = {
  texts: LocalizedTextInput[]
}
export function LocalizedTexts(props: LocalizedTextsInput) {
  return (
    <React.Fragment>
      {props.texts.map(p => <LocalizedText key={p.language} {...p} />)}
    </React.Fragment>
  );
}