import { Action } from './renditions';

export type ViewVersion = "initial" | "unified";

export function isInIframe(window: Window) {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isInPopup(window: Window) {
  return window.opener !== null;
}

export function getPostMessageTarget(window: Window) : Window | null {
  if (isInPopup(window)) {
    return window.opener as Window;
  }
  else if (isInIframe(window)) {
    return window.parent;
  } else {
    return null;
  }
}

export function getAuthority(url: URL) {
  return `${url.protocol}//${url.host}`;
}

export function onRender(callback: () => void) {
  window.addEventListener('DOMContentLoaded', callback);
  if (document.readyState === "complete" || document.readyState === "interactive") {
    callback();
  }
}

export function onReactRender(callback: (element: HTMLElement | null) => void) {
  window.addEventListener('DOMContentLoaded', () => callback(null));
  if (document.readyState === "complete" || document.readyState === "interactive") {
    callback(null);
  }
}

export function parseAction(login_hint: string | null | undefined) : Action | null {
  if (!login_hint) return null;

  const segments = login_hint.split(" ");
  const actionCandidate = segments.find(s => s.startsWith('action:'));
  if (!actionCandidate) return null;
  const action = actionCandidate.replace('action:','');
  if (['login', 'sign', 'approve', 'confirm', 'accept'].includes(action as any)) return action as Action;
  return null;
}

export function isDashboardEmbed() {
  const referrer = document.referrer;

  if (referrer === 'http://localhost:5001/') return true;
  if (referrer === 'https://dashboard-test.criipto.com/') return true;
  if (referrer === 'https://dashboard.criipto.com/') return true;
  if (referrer.endsWith('--criipto-dashboard-test.netlify.app/')) return true;

  return false;
}