import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Action, ApplicationErrorStrategy, DanishMitID_BrokerLandingPageModel } from '../renditions';
import Frame from '../components/Frame';

import './DanishMitID.css';
import styles from './BrokerLandingPage.module.css';
import DanishMitIDHeader from './Header';
import { Language } from '@criipto/verify-react';
import DanishMitIDFooter from './Footer';

import LocalIdPTabs from './components/LocalIdPTabs';
import LocalIdp from './LocalIdp';
import SessionStale from './SessionStale';
import CoreClient from './CoreClient';

function Mounter(props: {
  mount: boolean
  children: React.ReactNode
}) {
  const {mount, children} = props;
  const [mounted, setMounted] = useState(mount);
  const show = mount;

  useEffect(() => {
    if (mount) setMounted(true)
  }, [mount]);

  if (!mounted) return null;
  return <div className={cx(styles.mounted, {[styles.show]: show})}>{children}</div>;
}

export function BrokerLandingPage(props: {
  model: DanishMitID_BrokerLandingPageModel,
  action: Action,
  coreClient: React.ReactNode
  localIdP: React.ReactNode
  stale?: React.ReactNode
}) {
  const {model, action, stale} = props;
  const [tab, setTab] = useState<'mitid' | 'localidp'>('mitid');
  const localIdpEnabled = !!props.model.nemLoginAuthenticateEndpoint;

  return (
    <React.Fragment>
      <DanishMitIDHeader
        action={action}
        language={model.language}
      />
      <Frame>
        {stale ? stale : (
          <>
            {localIdpEnabled ? <LocalIdPTabs selected={tab} onSelect={setTab}  /> : null}
            <Mounter mount={tab === 'localidp'}>{props.localIdP}</Mounter>
            <Mounter mount={tab === 'mitid'}>{props.coreClient}</Mounter>
          </>
        )}
      </Frame>
      <DanishMitIDFooter language={model.language as Language} />
    </React.Fragment>
  );
}

export default function Container(props: {
  model: DanishMitID_BrokerLandingPageModel,
  action: Action,
  errorStrategy: ApplicationErrorStrategy
}) {
  const {model} = props;
  const {livenessUrl} = model;
  const fresh = useFresh(livenessUrl);

  const coreClient = (
    <CoreClient
      url={model.coreClientScriptSource}
      language={model.language}
      errorStrategy={props.errorStrategy} 
    />
  );
  const localIdP = (
    <LocalIdp model={props.model} />
  )

  if (!fresh) {
    return (
      <BrokerLandingPage 
        {...props}
        coreClient={coreClient}
        localIdP={localIdP}
        stale={<SessionStale retryUrl={props.model.retryUrl} cancelUrl={props.model.cancelUrl} />}
      />
    )
  }

  return (
    <BrokerLandingPage
      {...props}
      coreClient={coreClient}
      localIdP={localIdP}
    />
  );
}

function useFresh(livenessUrl: string) {
  const [fresh, setFresh] = useState(true);

  useEffect(() => {
    let isSubscribed = true;

    const interval = 30000;
    const poll = async () => {
      try {
        const response = await fetch(livenessUrl);
        if (response.status === 404) {
          setFresh(false);
          return;
        }

        setTimeout(() => {
          poll();
        }, interval);
      } catch {
        setTimeout(() => {
          poll();
        }, interval);
      }
    };

    setTimeout(() => {
      poll();
    }, interval);

    return () => {
      isSubscribed = false;
    };
  }, [livenessUrl]);
  return fresh;
}