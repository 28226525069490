import React, { useCallback, useEffect, useState } from 'react';
import { Action, SwedishBankID_StartAppModel } from '../renditions';
import Frame from '../components/Frame';
import Footer from '../components/Footer';

import './SwedishBankID.css';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import ActionHeader from '../components/ActionHeader';
import HiddenFormFields from '../components/HiddenFormFields';

export default function StartApp(props: {
  model: SwedishBankID_StartAppModel,
  action: Action,
}) {
  const {model, action} = props;
  const [error, setError] = useState<null | string>(null);

  const poll = useCallback(async () => {
    const response = await fetch(model.pollUrl);
    if (response.status >= 400) {
      document.body.classList.add('has-error');
      setError(await response.clone().text());
    } else if (response.status === 202) {
      return response.status;
    } else {
      const payload : {targetUrl: string} = await response.json();
      window.location.href = payload.targetUrl;
    }
    return response.status;
  }, [model.pollUrl]);

  useEffect(() => {
    let subscribed = true;

    const recursivePoll = async (delay: number) => {
      if (!subscribed) return;
      const status = await poll();
      if (status === 202) setTimeout(() => recursivePoll(delay), delay);
    }
    
    recursivePoll(5000);

    return () => {
      subscribed = false;
    }
  }, [model.pollUrl]);

  return (
    <React.Fragment>
      <ActionHeader
        action={action}
        language={model.language}
        suffix="BankID"
      />
      <form method="POST" action={`/SEBankIdAsync/Cancel`}>
        <HiddenFormFields fields={model.formParameters} />
        <div id="logo" className="default-hidden"></div>
        <div id="loader" className="default-hidden"></div>
        {/** Error frame */}
        <Frame className="centered sebankid-another-device default-hidden has-error-show" id="sebankid_error_frame">
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Sorry, but something went wrong. Please try again in a few minutes.`},
                {language: 'sv', text: `Förlåt, men något gick fel. Försök gärna igen om en liten stund.`}
              ]
            }
          />
          <p id="error" className="error">{error}</p>
        </Frame>
        {/** SSN input frame */}
        <Frame className="sebankid-startapp centered text-center has-error-hide" id="sebankid_startapp_frame">
          <div className="default-hidden" id="sebankid_startapp_message_before"></div>
          <p id="sebankid_startapp_message">
            <LocalizedTexts
              texts={
                [
                  {language: 'en', text: `Start the BankID application`},
                  {language: 'sv', text: `Starta BankID-applikationen`}
                ]
              }
            />
          </p>
          <button type="submit" className="button button-primary" id="sebankid_anotherdevice_cancel">
            <LocalizedTexts
              texts={
                [
                  {language: 'en', text: `Cancel`},
                  {language: 'sv', text: `Avbryt`}
                ]
              }
            />
          </button>
          <div className="default-hidden" id="sebankid_startapp_message_after"></div>
        </Frame>
      </form>
      <Footer language={model.language as Language} />
    </React.Fragment>
  );
}
