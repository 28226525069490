import React from 'react';
import cx from 'classnames';

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/600.css";

import { ViewVersion, DutchIdin_SelectBankPageModel, Action } from "../renditions"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Language } from '@criipto/verify-react';
import Frame from '../components/Frame';
import * as logos from './logos';

import styles from './SelectBank.module.css';
import { LocalizedTexts } from '../components/Language';

export type SelectBankProps = {
  requestUrl: URL
  viewVersion: ViewVersion
  model: DutchIdin_SelectBankPageModel
  action: Action
}

export const BANK_BIC = [
  'ABNANL2A',
  'ASNBNL21',
  'BUNQNL2A',
  'INGBNL2A',
  'RABONL2U',
  'RBRBNL21',
  'SNSBNL2A'
] as const;

export const BANK_NAMES : {[key in typeof BANK_BIC[number]]: string} = {
  ABNANL2A: "Abn Ambro Bank",
  ASNBNL21: "Asn Bank",
  BUNQNL2A: "Bunq Bank",
  INGBNL2A: "Ing Bank",
  RABONL2U: "Rabobank",
  RBRBNL21: "RegioBank",
  SNSBNL2A: "Sns Bank"
}

function getHints(loginHint: string | null) {
  if (!loginHint) return [];
  return loginHint?.split(' ') ?? []
}

function generateHref(requestUrl: URL, bic: typeof BANK_BIC[number]) {
  const url = new URL(requestUrl.href);
  const hints = getHints(url.searchParams.get('login_hint')).filter(s => !s.startsWith('BIC'));
  hints.push(`BIC:${bic}`);
  url.searchParams.set('login_hint', hints.join(' '));
  return url.href;
}

export default function SelectBank(props: SelectBankProps) {
  const {requestUrl, model} = props;
  const {language, cancelUrl} = model;

  return (
    <React.Fragment>
      <Header
        className={styles.header}
        title={
          <LocalizedTexts
              texts={[
                {language: 'en', text: 'Identify yourself to access your account'},
                {language: 'nl', text: 'Identificeer uzelf voor toegang tot uw account'},
              ]}
            />
        }
      />
      <Frame className={styles.frame}>
        <div className={cx(styles.banks, 'nl-idin-bank-select')}>
          {BANK_BIC.map(bic => (
            <BankButton
              requestUrl={requestUrl}
              bic={bic}
            />
          ))}
        </div>
        {cancelUrl ? (
          <div className={cx(styles.cancel, 'nl-idin-cancel')}>
            <a href={cancelUrl} className={styles.button}>
              Cancel
            </a>
            </div>
        ) : null}
      </Frame>
      <Footer language={language as Language} />
    </React.Fragment>
  )
}

function BankButton(props: {
  requestUrl: URL,
  bic: typeof BANK_BIC[number] 
}) {
  return (
    <a
      href={generateHref(props.requestUrl, props.bic)}
      className={cx(styles.bank, 'nl-idin-bank')}
      title={BANK_NAMES[props.bic]}
      aria-label={BANK_NAMES[props.bic]}
    >
      <img src={logos[props.bic]} alt={`${BANK_NAMES[props.bic]} logo`} />
    </a>
  )
}