import React from 'react';
import { Action, SwedishBankID_CancelMessageModel } from '../renditions';
import Frame from '../components/Frame';
import Footer from '../components/Footer';

import './SwedishBankID.css';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import ActionHeader from '../components/ActionHeader';

export default function Cancel(props: {
  model: SwedishBankID_CancelMessageModel,
  action: Action,
}) {
  const {model, action} = props;

  return (
    <React.Fragment>
      <ActionHeader
        action={action}
        language={model.language}
        suffix="BankID"
      />
      <Frame className="centered" id="sebankid_cancel_frame">
        <LocalizedTexts
          texts={
            [
              {language: 'en', text: `Login cancelled.`},
              {language: 'sv', text: `Login avbröts.`}
            ]
          }
        />
      </Frame>
      <Footer language={model.language as Language} />
    </React.Fragment>
  );
}
