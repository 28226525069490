import React from 'react';
export default function HiddenFormFields(props: {
  fields: {[key: string]: string}
}) {
  return (
    <React.Fragment>
      {Object.keys(props.fields).map(key => (
        <input key={key} type="hidden" name={key} value={props.fields[key]} />
      ))}
    </React.Fragment>
  )
}