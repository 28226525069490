import React, { useState } from 'react';
import { Action, DanishMitID_CprEntryModel, DanishMitID_CprEntryPostModel } from '../renditions';
import Frame from '../components/Frame';
import { LocalizedTexts } from '../components/Language';
import HiddenFormFields from '../components/HiddenFormFields';

import './DanishMitID.css';
import DanishMitIDHeader from './Header';
import { Language } from '@criipto/verify-react';
import { isDashboardEmbed } from '../utils';
import DanishMitIDFooter from './Footer';

const cprInputName : keyof DanishMitID_CprEntryPostModel = "cpr"
const rememberInputName : keyof DanishMitID_CprEntryPostModel = "rememberCpr"

export default function CprEntry(props: {
  model: DanishMitID_CprEntryModel,
  action: Action
}) {
  const {model, action} = props;
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    setSubmitted(true);
  }

  return (
    <React.Fragment>
      <DanishMitIDHeader
        action={action}
        language={model.language}
      />
      <Frame>
        <div className="mitid-border">
          <form method="POST" action={model.formAction} className="dkmitid-cpr-form" onSubmit={handleSubmit}>
            <HiddenFormFields fields={model.formParameters} />
            <div className="form-group">
              <label htmlFor="dkmitid_cprentry_input">
                <LocalizedTexts
                  texts={
                    [
                      {language: 'en', text: `CPR number`},
                      {language: 'da', text: `CPR nummer`}
                    ]
                  }
                />
              </label>
              <input
                id="dkmitid_cprentry_input" name={cprInputName} type="text" className="input-text"
                autoComplete="off" placeholder={model.language === 'da' ? 'DDMMÅÅXXXX' : 'DDMMYYXXXX'}
                autoFocus={isDashboardEmbed() ? false : true} required
              />
            </div>
            {model.error ? (
              <div className="frame-error centered">
                  <p className="error" id="dkmitid_cprentry_error">{model.error}</p>
              </div>
            ) : null}
            <button type="submit" className="button button-primary" id="dkmitid_cprentry_submit" disabled={submitted}>
              <LocalizedTexts
                texts={
                  [
                    {language: 'en', text: `Continue`},
                    {language: 'da', text: `Fortsæt`}
                  ]
                }
              />

              <svg xmlns="http://www.w3.org/2000/svg" focusable="false" className="mitid--button-icon" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41l-6.58-6.6c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z"></path></svg>
            </button>
            {model.cprLookupStrategy === "askuser" ? (
              <div className="form-group checkbox remember-me" id="dkmitid_cprentry_remember_formgroup">
                <input name={rememberInputName} id="dkmitid_cprentry_remember" type="checkbox" className="input-checkbox" />
                <div className="checkmark"></div>
                <label htmlFor="dkmitid_cprentry_remember">
                  <LocalizedTexts
                    texts={
                      [
                        {language: 'en', text: `Remember me (for 1 year)`},
                        {language: 'da', text: `Husk mig (i 1 år)`}
                      ]
                    }
                  />
                </label>
              </div>
            ) : null}
          </form>
        </div>
      </Frame>
      <DanishMitIDFooter language={model.language as Language} />
    </React.Fragment>
  );
}