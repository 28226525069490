import React from 'react';
import { Action, DanishMitID_ContinueModel } from '../renditions';
import Frame from '../components/Frame';

import './DanishMitID.css';
import DanishMitIDHeader from './Header';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import DanishMitIDFooter from './Footer';

export default function Continue(props: {
  model: DanishMitID_ContinueModel,
  action: Action
}) {
  const {model, action} = props;

  return (
    <React.Fragment>
      <DanishMitIDHeader
        action={action}
        language={model.language}
      />
      <Frame className="centered">
        <div className="mitid-border centered-horizontal">
          <a href={model.href} className="button button-primary" id="dkmitid_appswitch_continue">
            <span>
              <LocalizedTexts
                texts={
                  [
                    {language: 'en', text: `Continue to`},
                    {language: 'da', text: `Fortsæt til`},
                    {language: 'sv', text: "Fortsätta att"},
                    {language: 'nb', text: "Fortsette å"}
                  ]
                }
              />&nbsp;
              {model.appName}
            </span>
          </a>
      </div>
      </Frame>
      <DanishMitIDFooter language={model.language as Language} />
    </React.Fragment>
  );
}
