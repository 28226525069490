import { useLayoutEffect, useMemo, useRef } from "react";
import { ApplicationErrorStrategy, DanishMitID_CoreClientModel } from "../renditions"
import { useNavigate, useScreen } from "../screen-router";

export default function CoreClient(props: {
  url: string
  language: string
  errorStrategy: ApplicationErrorStrategy
}) {
  const {url, errorStrategy, language} = props;
  const screen = useScreen();
  const coreClientRef = useRef<HTMLSpanElement | null>(null);
  const navigate = useNavigate();
  
  const events = useMemo<MitIDCoreClientEvents>(() => {
    return {
      errorCallback: errorStrategy === 'legacy' ? (errorEndpoint, errorCandidate) => {
        navigate({
          ...screen,
          screen: {
            screen: 'DanishMitID/Error',
            rendition: {
              language,
              error: errorCandidate?.message ?? "No error message"
            }
          }
        })
      } : undefined
    }
  }, [errorStrategy, language, navigate]);

  useLayoutEffect(() => {
    if (!coreClientRef.current) return;
    
    (async () => {
      const response = await fetch(url, {
        headers: {
          accept: 'application/json'
        }
      });
      if (response.status >= 400) {
        throw new Error(await response.text());
      }
  
      const payload = await response.json() as DanishMitID_CoreClientModel;
      initializeCoreClient(coreClientRef.current!, defaultScriptLoader, payload, events);
    })();
  }, [events]);

  return (
    <span id="coreClientParent" ref={coreClientRef} />
  );
}

type MitIDCoreClientError = {
  timestamp?: string
  code?: string
  message?: string
  correlationId?: string
}

interface MitIDCoreClientEvents {
  errorCallback?: (errorEndpoint: string, errorCandidate?: MitIDCoreClientError) => void
}

function defaultScriptLoader(model: DanishMitID_CoreClientModel, onload: () => void) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.crossOrigin = 'anonymous';
  script.onload = onload;
  script.src = model.CoreClientUrl;
  script.id = 'mitidCoreClient';
  script.integrity = `${model.CoreClientAlgorithm}-${model.CoreClientChecksum}`;
  document.getElementsByTagName('head')[0].appendChild(script);
}

function initializeCoreClient(
  element: HTMLElement,
  scriptLoader: typeof defaultScriptLoader,
  model: DanishMitID_CoreClientModel,
  events: MitIDCoreClientEvents
) {
  if (model.RetryEndpoint?.length) {
    window.location.href = model.RetryEndpoint;
    return;
  }

  scriptLoader(model, () => {
    window.InitializeCoreClient({
      domReference: element.getAttribute('id') ?? element,
      identityClaimCallback: (user: any) => {
        console.debug('Executing identityClaimCallback. User is ' + user);

        if (user === '') return false;

        // TODO: refactor to fetch
        var request = new XMLHttpRequest();
        var identityClaimEndpoint = model.IdentityClaimEndpoint;
        request.open("PUT", identityClaimEndpoint);
        request.onreadystatechange = function (evt: any) {
            console.debug("identity endpoint response", evt);
        }
        request.send();
        return true;
      },
      finalizationCallback: (authorizationCode: string) => {
        console.debug('Executing finalizationCallback');
        const callbackUrl = new URL(model.CallbackEndpoint);
        callbackUrl.searchParams.set('code', authorizationCode);
        window.location.href = callbackUrl.href;
      },
      cancelAuthenticationCallback: () => {
        console.debug('cancelAuthenticationCallback has been invoked from Core Client ');
        window.location.href = model.CancelEndpoint
      },
      aux: model.CoreClientAux,
      errorCallback: (errorCandidate?: MitIDCoreClientError) => {
        console.debug('errorCallback has been invoked from Core Client ');
        console.debug(errorCandidate);

        if (events.errorCallback) {
          events.errorCallback(model.ErrorEndpoint, errorCandidate);
          return;
        }

        let errorMessage = errorCandidate?.message || "No error message";
        if (errorCandidate?.code) {
          errorMessage = `${errorCandidate.code}: ${errorMessage}`;
        }

        const error = {
          "timestamp": errorCandidate?.timestamp || (new Date()).toISOString(),
          "errorCode": "mitid_core_client_error",
          errorMessage,
          "correlationId" : errorCandidate?.correlationId || "No correlation id"
        }
        let errorEndpoint = model.ErrorEndpoint;
        var qps = (new URLSearchParams(error)).toString();
        var sep = errorEndpoint.includes("?") ? "&" : "?";
        errorEndpoint += sep + qps;
        window.location.href = errorEndpoint;
      }
    });
  });
}

interface CoreClientOptions {
  domReference: string | HTMLElement
  identityClaimCallback(user: string): void
  finalizationCallback(authorizationCode: string): void
  cancelAuthenticationCallback(): void
  aux: string
  errorCallback(errorCandidate?: MitIDCoreClientError): void
}

declare global {
  interface Window {
    InitializeCoreClient: (options: CoreClientOptions) => void;
  }
}