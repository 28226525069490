import React from "react";
import cx from 'classnames';

import './Header/Header.css';
export default function Header(props: {title: string | React.ReactElement, className?: string}) {
  return (
    <React.Fragment>
      <header id="header" className="default-hidden desktop-show">
        <div className="inner"></div>
      </header>
      <h1 id="headline" className={cx('default-hidden desktop-show centered', props.className)}>
        {props.title}
      </h1>
    </React.Fragment>
  )
}