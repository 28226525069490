import React from 'react';
import { Action, SwedishBankID_ErrorMessageModel } from '../renditions';
import Frame from '../components/Frame';
import Footer from '../components/Footer';

import './SwedishBankID.css';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import ActionHeader from '../components/ActionHeader';

export default function Error(props: {
  model: SwedishBankID_ErrorMessageModel,
  action: Action,
}) {
  const {model, action} = props;

  return (
    <React.Fragment>
      <ActionHeader
        action={action}
        language={model.language}
        suffix="BankID"
      />
      <Frame className="centered has-error-show" id="sebankid_error_frame">
        <LocalizedTexts
          texts={
            [
              {language: 'en', text: `Sorry, but something went wrong. Please try again in a few minutes.`},
              {language: 'sv', text: `Förlåt, men något gick fel. Försök gärna igen om en liten stund.`}
            ]
          }
        />
        <p id="error" className="error">{model.details}</p>
      </Frame>
      <Footer language={model.language as Language} />
    </React.Fragment>
  );
}
