import React from 'react';
import { LocalizedTexts } from './Language';

export default function CancelTexts() {
  return (
    <LocalizedTexts
      texts={[
        {language: 'en', text: 'Cancel'},
        {language: 'da', text: 'Annuller'},
        {language: 'sv', text: 'Avbryt'},
        {language: 'nb', text: 'Avbryt'},
        {language: 'fi', text: 'Keskeytä'},
      ]}
    />
  )
}