import type { ResponseType, AuthorizeResponse } from '@criipto/auth-js/dist/types';
import {Result} from '@criipto/verify-react';
import { getAuthority, getPostMessageTarget } from './utils';

export function addResponseParmas(responseType: ResponseType, params: URLSearchParams, response: AuthorizeResponse) {
  if (response.error) {
    params.set('error', response.error);
    if (response.error_description) params.set('error_description', response.error_description);
  } else if (responseType === 'code') {
    if (!response.code) throw new Error('Attempted to construct code response with no code available.');
    params.set('code', response.code);
  } else if (responseType === 'id_token') {
    if (!response.id_token) throw new Error('Attempted to construct id_token response with no id_token available.');
    params.set('id_token', response.id_token);
  }
  
  if (response.state) {
    params.set('state', response.state);
  }
  return params;
}

export type ResponseURLOptions = {
  response: AuthorizeResponse
  redirectUri: string
  responseType: ResponseType,
  responseMode: 'query' | 'fragment'
}
export function createResponseURL(options: ResponseURLOptions) {
  const {redirectUri, response, responseMode, responseType} = options;
  const url = new URL(redirectUri);
  
  let params = responseMode === 'query' ? new URLSearchParams(url.search) : new URLSearchParams();
  params = addResponseParmas(responseType, params, response);

  if (responseMode === 'query') {
    url.search = params.toString();
  } else {
    url.hash = params.toString();
  }

  return url.toString();
}

type RedirectResponse = {
  type: 'redirect',
  url: string
}
type PostMessageResponse = {
  type: 'post_message'
  url: string
}
type FormPostResponse = {
  type: 'form_post'
  url: string,
  body: URLSearchParams
}
export type Response = RedirectResponse | PostMessageResponse | FormPostResponse;
type ResponseOptions = Omit<ResponseURLOptions, 'responseMode'> & {
  responseMode?: ResponseURLOptions['responseMode'] | 'post_message' | 'form_post'
}
export function createResponse(options: ResponseOptions) : Response {
  const responseMode = options.responseMode || 'query';
  const url = createResponseURL({
    ...options,
    responseMode: (responseMode === 'post_message' || responseMode === 'form_post') ? 'query' : responseMode
  });

  if (responseMode === 'post_message') {
    return {
      type: 'post_message',
      url: url.toString()
    };
  }
  if (responseMode === 'form_post') {
    return {
      type: 'form_post',
      url: options.redirectUri,
      body: addResponseParmas(options.responseType, new URLSearchParams(), options.response)
    };
  }
  if (responseMode === 'fragment' || responseMode === 'query') {
    return {
      type: 'redirect',
      url: url.toString()
    };
  }

  assertUnreachableResponseMode(responseMode);
}

type ReactResponseOptions = Omit<ResponseOptions, 'response'> & {result: Result, state?: string};
export function convertReactResult(options: ReactResponseOptions) {
  const {result} = options;
  return createResponse({
    ...options,
    response: "error" in result ? {
      error: result.error,
      error_description: result.error_description,
      state: result.state ?? options.state
    } : "code" in result ? {
      code: result.code,
      state: result.state
    } : "id_token" in result ? {
      id_token  : result.id_token,
      state: result.state 
    } : result instanceof Error ? {
      error: result.message,
      state: options.state
    } : result
  });
}

function assertUnreachableResponseMode(_value: never): never {
  throw new Error(`Unknown response mode ${_value}`);
}

export function executeResponse(window: Window, response: Response) {
  if (response.type === 'redirect') {
    window.location.href = response.url;
    return;
  }
  if (response.type === 'post_message') {
    const target = getPostMessageTarget(window);
    if (target === null) throw new Error('Not able to find valid post_message target (no window.opener or window.top)');
    target.postMessage(response.url, getAuthority(new URL(response.url)));
    return;
  }
  if (response.type === 'form_post') {
    const form = window.document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', response.url);

    response.body.forEach((value, key) => {
      const input = window.document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', value);
      form.appendChild(input);
    });

    window.document.body.appendChild(form);
    form.submit();
    return;
  }
  assertUnreachableResponseCase(response);
}

function assertUnreachableResponseCase(_value: never): never {
  throw new Error(`Unknown response case ${_value}`);
}