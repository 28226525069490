import React from 'react';
import Header from "./Header";
import { LocalizedTexts } from "./Language";
import { Action } from "../renditions";

export default function ActionHeader(props: {
  language: string,
  suffix: string,
  action: Action
}) {

  const actionText = 
    (
      actionTexts(props.action).find(s => s.language === props.language) ??
      actionTexts(props.action).find(s => s.language === 'en')!
    ).text;

  return (
    <Header
      title={
        <LocalizedTexts
          texts={
            [
              {language: 'en', text: `${actionText} with ${props.suffix}`},
              {language: 'da', text: `${actionText} med ${props.suffix}`},
              {language: 'sv', text: `${actionText} med ${props.suffix}`}
            ]
          }
        />
      }
    />
  )
}

export function actionTexts(action: Action) {
  const actionTexts = 
    action === 'sign' ? [
      {language: 'en', text: 'Sign'},
      {language: 'da', text: 'Underskriv'},
      {language: 'sv', text: 'Underteckna'}
    ] :
    action === 'approve' ? [
      {language: 'en', text: 'Approve'},
      {language: 'da', text: 'Godkend'},
      {language: 'sv', text: 'Godkänna'}
    ] :
    action === 'confirm' ? [
      {language: 'en', text: 'Confirm'},
      {language: 'da', text: 'Bekræft'},
      {language: 'sv', text: 'Bekräfta'}  
    ] :
    action === 'accept' ? [
      {language: 'en', text: 'Accept'},
      {language: 'da', text: 'Accepter'},
      {language: 'sv', text: 'Acceptera'}  
    ] :
    action === 'login' ? [
      {language: 'en', text: 'Log on'},
      {language: 'da', text: 'Log på'},
      {language: 'sv', text: 'Logga in'}
    ] :
    assertUnreachableAction(action);

  return actionTexts;
}

function assertUnreachableAction(_value: never): never {
  throw new Error(`Unknown action ${_value}`);
}