import React from "react";

interface Props {
  children: React.ReactNode,
  className?: string,
  id?: string
}

export default function Frame(props: Props) {
  return (
    <main id={props.id ?? 'frame'} className={`frame centered ${props.className || ''}`}>
      {props.children}
    </main>
  );
}