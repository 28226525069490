import "@fontsource/ibm-plex-sans/700.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/500.css";
import cx from 'classnames';

import { LocalizedTexts } from "../components/Language";
import { DanishMitID_BrokerLandingPageModel, DanishMitID_NemLogin_LocalIdP, DanishMitID_NemLogin_LocalIdPs } from "../renditions";
import localidp_logo from './images/localidp.svg';

import styles from './LocalIdp.module.css';
import { useEffect, useState } from "react";
import { Language } from "@criipto/verify-react";

function makeUrl(nemLoginAuthenticateEndpoint: string, entityId: string) {
  const url = new URL(nemLoginAuthenticateEndpoint);
  url.searchParams.set('entityID', entityId);
  return url.href;
}

export function LocalIdp(props: {
  nemLoginAuthenticateEndpoint: NonNullable<DanishMitID_BrokerLandingPageModel["nemLoginAuthenticateEndpoint"]>,
  identityProviders: DanishMitID_NemLogin_LocalIdP[],
  language: Language
}) {
  const [search, setSearch] = useState('');
  const items = props.identityProviders.flatMap(i => i.connectedOrganization.map(o => ({
    entityId: i.entityId,
    mainName: i.displayName,
    subName: o.name,
    cvr: o.cvr
  }))).filter(s => s.entityId.includes(search) || s.mainName.includes(search) || s.subName.includes(search) || s.cvr.includes(search));

  const [remembered, setRemembered] = useState(() => {
    if (localStorage.getItem('localidp_remembered')) {
      try {
        return JSON.parse(localStorage.getItem('localidp_remembered')!) as (typeof items)[0];
      } catch (err) {
        localStorage.removeItem('localidp_remembered');
        return null;
      }
    }
    return null;
  });
  const [shouldRemember, setShouldRemember] = useState(true);
  const handleClick = (event: React.MouseEvent, item: (typeof items)[0]) => {
    if (shouldRemember) {
      setRemembered(item);
      localStorage.setItem('localidp_remembered', JSON.stringify(item));
    } else {
      setRemembered(null);
      localStorage.removeItem('localidp_remembered');
    }
  }

  useEffect(() => {
    if (shouldRemember && remembered) {
      localStorage.setItem('localidp_remembered', JSON.stringify(remembered));
    } else {
      localStorage.removeItem('localidp_remembered');
    }
  }, [shouldRemember, remembered]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h2>
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Select organization`},
                {language: 'da', text: `Vælg organisation`}  
              ]
            }
          />
        </h2>
        <img src={localidp_logo} />
      </header>
      <div className={styles.search}>
        <input
          value={search}
          onChange={event => setSearch(event.target.value)}
          placeholder={props.language === 'da' ? 'Søg efter organisation...' : 'Search for organization'}
        />
        {search?.length ? (
          <div className={styles.clear} onClick={() => setSearch('')}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.125 10.875L6 6.71875L1.84375 10.875C1.65625 11.0625 1.34375 11.0625 1.125 10.875C0.9375 10.6562 0.9375 10.3438 1.125 10.1562L5.28125 6L1.125 1.875C0.9375 1.6875 0.9375 1.375 1.125 1.15625C1.34375 0.96875 1.65625 0.96875 1.84375 1.15625L6 5.3125L10.125 1.15625C10.3125 0.96875 10.625 0.96875 10.8438 1.15625C11.0312 1.375 11.0312 1.6875 10.8438 1.875L6.6875 6L10.8438 10.1562C11.0312 10.3438 11.0312 10.6562 10.8438 10.875C10.625 11.0625 10.3125 11.0625 10.125 10.875Z" fill="#262E52"/>
            </svg>
          </div>
        ) : null}
      </div>
      {remembered ? (
        <a href={makeUrl(props.nemLoginAuthenticateEndpoint, remembered.entityId)} onClick={(event) => handleClick(event, remembered)} className={cx(styles.item, styles.remembered)}>
          <div className={styles.label}>
            <LocalizedTexts
              texts={
                [
                  {language: 'en', text: `Last used`},
                  {language: 'da', text: `Sidst anvendt`}  
                ]
              }
            />
          </div>
          <div className={styles.content}>
            <div className={styles.top}>
              <strong>{remembered.mainName}</strong>
              <span className={styles.cvr}>CVR: {remembered.cvr}</span>
            </div>
            <span>{remembered.subName}</span>
          </div>
        </a>
      ) : null}
      <div className={styles.list}>
        <ul>
          {items.map(item => (
            <li key={item.entityId + item.cvr}>
              <a href={makeUrl(props.nemLoginAuthenticateEndpoint, item.entityId)} onClick={(event) => handleClick(event, item)} className={styles.item}>
                <div className={styles.content}>
                  <div className={styles.top}>
                    <strong>{item.mainName}</strong>
                    <span className={styles.cvr}>CVR: {item.cvr}</span>
                  </div>
                  <span>{item.subName}</span>
                </div>
              </a>
            </li>
          ))}
        </ul>
        <div className={styles.shadow}></div>
      </div>
      <div className={styles.remember}>
        <input type="checkbox" checked={shouldRemember} onChange={(event) => setShouldRemember(event.target.checked)} id="localidp_remember" />
        <label htmlFor="localidp_remember">
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Remember my choice`},
                {language: 'da', text: `Husk mit valg`}  
              ]
            }
          />
        </label>
      </div>
    </div>
  );
}

LocalIdp.Loader = () => {
  return (
    <div className={cx(styles.wrapper, styles.loader)}>
      <div className={styles.spinner}>
        <div className={styles.bar}></div>
      </div>
      Loading ...
    </div>
  );
}

export default function Container(props: {
  model: Pick<DanishMitID_BrokerLandingPageModel, 'nemLoginAuthenticateEndpoint' | 'nemLoginLocalIdpsEndpoint' | 'language'>
}) {
  const {model} = props;
  const enabled = !!model.nemLoginAuthenticateEndpoint;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<DanishMitID_NemLogin_LocalIdPs | null>(null);

  useEffect(() => {
    console.log('LocalIdp.useEffect');
    if (!model.nemLoginLocalIdpsEndpoint) return;

    let subscribed = true;
    (async () => {
      setLoading(true);
      const response = await localIdpLoader(model.nemLoginLocalIdpsEndpoint!);
      setLoading(false);
      setResponse(response);
    })();
    return () => {
      subscribed = false;
    };
  }, [model.nemLoginLocalIdpsEndpoint]);
  
  if (!enabled) return null;

  return (
    <>
      {loading ? <LocalIdp.Loader /> : (
        <LocalIdp 
          language={model.language as Language}
          nemLoginAuthenticateEndpoint={model.nemLoginAuthenticateEndpoint!}
          identityProviders={response?.identityProviders ?? []}
        />
      )}
    </>
  )
}

export async function localIdpLoader(url: string) {
  const response = await fetch(url, {
    headers: {
      accept: 'application/json'
    }
  });
  if (response.status >= 400) {
    throw new Error(await response.text());
  }

  const payload = await response.json() as DanishMitID_NemLogin_LocalIdPs;
  return payload;
}