import React from 'react';

import Frame from '../components/Frame';
import { LocalizedTexts } from '../components/Language';

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/700.css";
import styles from './SessionStale.module.css';

export default function SessionStale(props: {
  retryUrl: string
  cancelUrl: string
}) {
  return (
    <Frame>
      <div className="mitid-border">
        <label className={styles.label}>
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Login session expired`},
                {language: 'da', text: `Login forsøg er udløbet`}
              ]
            }
          />
        </label>
        <p className={styles.body}>
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Your login session has expired and is no longer valid. Please try again.`},
                {language: 'da', text: `Dit login forsøg er udløbet og er ikke længere gyldig. Vær venlig at prøve igen.`}
              ]
            }
          />
        </p>
        <a href={props.retryUrl} className="button button-primary">
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Try again`},
                {language: 'da', text: `Prøv igen`}
              ]
            }
          />

          <svg xmlns="http://www.w3.org/2000/svg" focusable="false" className="mitid--button-icon" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 13h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41l-6.58-6.6c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L16.17 11H5c-.55 0-1 .45-1 1s.45 1 1 1z"></path></svg>
        </a>
        {/* <a href={props.cancelUrl} className={styles.cancel}>
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Cancel`},
                {language: 'da', text: `Annuller`}
              ]
            }
          />
        </a> */}
      </div>
    </Frame>
  )
}