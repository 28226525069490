import { useEffect, useMemo } from "react";
import { ClientConfiguration, Scenario, Screen } from "./renditions";

let renderRequestDomainAsCssClass = (requestDomain : string) => {
  let cssEscapedDomain = requestDomain.replace(/\./g, "-")
  return `host-${cssEscapedDomain}`;
}
let renderScreenAsCssClass = (screen: string) => {
  let cssEscapedScreen = screen.toLowerCase().replace(/\//g, "-")
  return `screen-${cssEscapedScreen}`;
}

export function generateCssClasses(props: {
  clientCssClass: string | null
  domain: string
  scenario: Scenario
  screen: Screen["screen"]
}) {
  const {domain, scenario, screen, clientCssClass} = props;
  const hostClass = renderRequestDomainAsCssClass(domain);
  const screenClass = renderScreenAsCssClass(screen);
  const classes = (
    [
      'broker',
      hostClass,
      scenario,
      clientCssClass,
      screen.startsWith('FinnishTelia') ? 'finland' : null,
      screen.startsWith('DanishMitID') ? 'dkmitid broker broker-mitid' : null,
      screen.startsWith('SwedishBankID') ? 'sebankid broker' : null,
      screenClass
    ].filter(id => id) as string[]
  )
  .flatMap(x => x.split(" "))
  .filter(id => id?.length) as string[];

  return classes;
}
  
export function GlobalDOM(props: {
  clientConfiguration: ClientConfiguration
  scenario: Scenario
  screen: Screen["screen"],
  domain: string,
  language?: string
}) {
  const {clientConfiguration, screen, scenario, domain, language} = props;
  const realmClass = clientConfiguration.css_class?.length ? clientConfiguration.css_class : null;

  const classes = useMemo(() => {
    return generateCssClasses({
      clientCssClass: realmClass,
      domain,
      screen,
      scenario
    });
  }, [realmClass, domain, screen, scenario])

  useEffect(() => {
    const classList = Array.from(document.body.classList);
    for (const existing of classList) {
      if (classes.includes(existing)) continue;
      if (!existing) continue;
      document.body.classList.remove(existing);
    }
    for (const className of classes) {
      if (classList.includes(className)) continue;
      if (!className) continue;
      document.body.classList.add(className);
    }
  }, [classes]);

  useEffect(() => {
    if (!language) return;
    for (const link of document.head.getElementsByTagName('link')) {
      const href = link.getAttribute('href');
      if (href?.includes('/Content/language/') && href.endsWith('.v2.css')) {
        const current = href.split('/Content/language/')[1].replace('.v2.css', '');
        if (current === language) return;
        link.setAttribute('href', href.replace(`${current}.v2.css`, `${language}.v2.css`));
        return;
      }
    }
  }, [language]);

  return null;
}