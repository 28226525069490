import React from 'react';
import Header from "../components/Header";
import { LocalizedTexts } from "../components/Language";
import { Action } from "../renditions";

export default function DanishMitIDHeader(props: {
  language: string,
  action: Action
}) {

  const actionText = 
    (
      actionTexts(props.action).find(s => s.language === props.language) ??
      actionTexts(props.action).find(s => s.language === 'da')!
    ).text;

  return (
    <Header
      title={
        <LocalizedTexts
          texts={
            [
              {language: 'en', text: `${actionText} with MitID`},
              {language: 'da', text: `${actionText} med MitID`}  
            ]
          }
        />
      }
    />
  )
}

export function actionTexts(action: Action) {
  const actionTexts = 
    action === 'sign' ? [
      {language: 'en', text: 'Sign'},
      {language: 'da', text: 'Underskriv'}  
    ] :
    action === 'approve' ? [
      {language: 'en', text: 'Approve'},
      {language: 'da', text: 'Godkend'}  
    ] :
    action === 'confirm' ? [
      {language: 'en', text: 'Confirm'},
      {language: 'da', text: 'Bekræft'}  
    ] :
    action === 'accept' ? [
      {language: 'en', text: 'Accept'},
      {language: 'da', text: 'Accepter'}  
    ] :
    action === 'login' ? [
      {language: 'en', text: 'Log on'},
      {language: 'da', text: 'Log på'}
    ] :
    assertUnreachableAction(action);

  return actionTexts;
}

function assertUnreachableAction(_value: never): never {
  throw new Error(`Unknown action ${_value}`);
}